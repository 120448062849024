import {computed} from 'vue';
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";


export default function useDateInput() {
    const store = useStore();
    const date = computed(() => store.getters.dateQuery)
    const setDate = (value) => {
        store.dispatch(Actions.SET_DATE_ACTION, {
            start: value.start,
            end: value.end
        });
    }

    return {
        date,
        setDate
    }
}
