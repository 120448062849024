import {computed} from 'vue';
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";


export default function useSearchInput() {
    const store = useStore();
    const search = computed(() => store.getters.searchQuery)
    let timeOut = null;

    const setSearch = (value, delay = 0) => {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
            store.dispatch(Actions.SET_SEARCH_ACTION, {
                query: value
            });
        }, delay)
    }

    return {
        search,
        setSearch
    }
}
